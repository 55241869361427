<template>
  <svg viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9305 1.06421H3.93048C3.40005 1.06421 2.89134 1.27492 2.51627 1.65C2.14119 2.02507 1.93048 2.53378 1.93048 3.06421V19.0642C1.93048 19.5946 2.14119 20.1033 2.51627 20.4784C2.89134 20.8535 3.40005 21.0642 3.93048 21.0642H15.9305C16.4609 21.0642 16.9696 20.8535 17.3447 20.4784C17.7198 20.1033 17.9305 19.5946 17.9305 19.0642V8.06421L10.9305 1.06421Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9305 1.06421V8.06421H17.9305" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconFileSolid'
}
</script>
